.mydivss {
  /* border: 3px solid pink; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.error-msg {
  color: red;
  margin-top: -0px !important;
}

.text-red {
  color: red;
  margin-left: 10px;
}

.closebtns {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 50px;
}

.uploadIconss {
  font-size: 30px;
}

input[type='file'] {
  display: none;
}

.amounts p {
  margin: 0 !important;
  margin: 5px 0px !important;
}
.amounts {
  margin-top: 50px;
}

.menuIcons {
  font-size: 28px;
}

.MuiFormHelperText-root {
  color: red !important;
}

/* .mytabless{
    width: 100%;
}
.mytabless,tr,td{
    border: 1px solid black;
    border-collapse: collapse;
    padding: 5px
} */

.pricess {
  min-width: 70px;
}
